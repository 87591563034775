import React from 'react'
import { Layout } from '../../components/Layout/DashboardLayout'

function RepresentativeHelp() {
  return (
    <Layout active={'Help'}>
    <div>Help</div>
    </Layout>
  )
}

export default RepresentativeHelp