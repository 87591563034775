import React from 'react'
import { Layout } from '../../components/Layout/DashboardLayout'

function InsuranceHelp() {
  return (
    <>
    <Layout active={'Help'}>
    <div>Help</div>
    </Layout>
    </>
  )
}

export default InsuranceHelp