import React from 'react'
import Navbar2 from '../../components/Navbar2'

function ContactUs() {
  return (
    <>
    <Navbar2 active={'Contact-Us'}/>
    <div>ContactUs</div>
    </>
  )
}

export default ContactUs